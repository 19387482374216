<template>
<v-container style="height: calc(100vH - 70px)" class="justify-center d-flex align-center text-center">
    <div>
      <p>{{  errorText }}</p>
    </div>
</v-container>
</template>

<script>
export default {
  name: 'Error',
  computed: {
    errorText() {
      return !this.$route.query.token_is_not_valid ? 'An Error Occurred! Please Try Again Later...' : 'This web invoice is not valid.'
    }
  }
}
</script>

<style scoped>

</style>
